import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import { GoogleLogin } from "@react-oauth/google";
import { Button, Card, message, Modal } from "antd";

const RulesText = () => (
  <>
    <p>
      Welcome to the Man Cave Fantasy Football Playoffs League 2024. See the
      instructions to enter the League, Site Description with Live Updates,
      League Rules & Scoring/Points System below.
    </p>
    <h1>Important Instructions to follow:</h1>
    <p>
      You must have a Gmail email address to log into the Man Cave Fantasy
      Football Playoffs. This site is built on a google platform &amp; it’s a
      must to have a google email to be able to log into the contest to enter
      team(s).
    </p>
    <p>
      Once you enter the site click on the “Sign in with Google” tab. Once you
      are in you can add multiple teams if you wish, each Team will require the
      entry fee amount.
    </p>
    <p>
      The site will have all 14 NFL teams that are in the playoffs and every
      player that is eligible to play in the playoffs from these 14 teams. Your
      roster must contain the following: 3 QB’s, 4 WR’s, 4 RB’s, 1 TE ,1 Place
      Kicker and 1 Team Defense/Special Teams.
    </p>
    <p>
      This is a total points league that you only set up a roster before the
      playoffs start. Your 14 Players will accumulate points based on stats
      taken from NFL API Stats Data The site point scoring system is listed
      below and, in the settings tab, once you are logged in.
    </p>
    <p>
      You’ll be able to scroll through all the NFL players to make your
      selections for your team roster. You can search for players also in the
      search menu to expedite your search. There is an add or remove tab if you
      have your team/player entered and want to make changes to your team. Once
      a player for a team is selected all the remaining players for that team
      will be grayed out and moved to the bottom of the available players list.
      They will become available again if you remove a player that was selected
      for a particular team.
    </p>
    <p>
      The league will be locked 30 mins before the 1st playoff game kickoff
      time.
    </p>
    <p>
      The site will update every team’s player points along with the league
      standings during the game as points are scored per the Man Cave FFP point
      system There is a refresh stats tab for the League Standings &amp;
      Rostered Players on the Home Page. The leaderboard will continue to update
      throughout each game. As teams are eliminated the players for those teams
      will be grayed out and show your remaining Rostered players that are still
      playing.
    </p>
    <p>
      Feel free to email me if you have trouble getting into the site. There
      will be a help tab on the front page of the site in the menu bar along
      with a Your Teams Tab, Add a Team Tab and a Logout tab.
    </p>
    <p>
      The entry fee will be $55. $50 from each team will be used towards the
      pot, for the top 4 team payouts. Once/if I get my admin cost of $200
      covered for the site set up fee, I will add that extra money towards a 5th
      place team payout. This will be for 40 plus teams entered into the League.
      Obviously, the payouts will get higher and higher by the more entries that
      we get. Please feel free to send out the site &amp; my Venmo link to
      anyone that wants to enter the contest. If anybody needs to pay through a
      different pay app, just send me a text or an email. Once the contest is
      locked Saturday 30 mins before the 1st game kickoff time. I will figure
      out the payouts for the top 4 teams &amp; 5th place team if applicable.
    </p>
    <h1>League Rules</h1>
    <p>
      Enter your team name to join the league. If you have multiple teams, click
      the "Add Team" button at the top of the page and enter the team name. You
      can add up to one player (including defense and kicker) from each team in
      the NFL playoffs. Once the playoffs start, you cannot change your lineup.
      You won't be able to see your leaguemate's teams or super bowl guesses
      until rosters are locked-in. Guess the total score of the Super Bowl. At
      the end of the playoffs, if a tiebreaker is necessary, this will be
      determined by whoever gets the closest to the actual score without
      guessing higher than the actual score.
    </p>
    <h2>You select for building your team:</h2>
    <p>3 Qb’s</p>
    <p>4 Running backs</p>
    <p>4 Receivers</p>
    <p>1 Tight-end</p>
    <p>1 Team Defense</p>
    <p>1 Kicker</p>
    <p>
      To view scoring settings, join the league and click on the "Settings" tab
      above your roster.
    </p>
    <h2>Scoring Point System</h2>
    <p>
      Note that a point is only counted when a player reaches the threshold for
      a point; for example, a player rushing for 59 yards will only receive 5
      points, and not 5.9.
    </p>
    <h3>Passing</h3>
    <h4>Passing Yards per Point</h4>
    <p>25.00</p>
    <h4>Passing TDs</h4>
    <p>6.0</p>
    <h4>Interceptions Thrown</h4>
    <p>-1.0</p>
    <h4>Passing 2-Pt Conv</h4>
    <p>2.0</p>
    <h3>Rushing</h3>
    <h4>Rushing Yards per Point</h4>
    <p>10.00</p>
    <h4>Rushing TDs</h4>
    <p>6.0</p>
    <h4>Rushing 2-Pt Conv</h4>
    <p>2.0</p>
    <h3>Receiving</h3>
    <h4>Receiving Yards per Point</h4>
    <p>10.00</p>
    <h4>Receiving TDs</h4>
    <p>6.0</p>
    <h4>Receiving 2-Pt Conv</h4>
    <p>2.0</p>
    <h3>Other</h3>
    <h4>Fumbles Lost</h4>
    <p>-1.0</p>
    <h4>Unordinary TDs</h4>
    <p>6.0</p>
    <h4>Bonus for 50+ Yard Touchdown - Pass, Rec, or Rush</h4>
    <p>2.0</p>
    <h3>Defense</h3>
    <h4>Touchdown</h4>
    <p>6.0</p>
    <h4>Safeties</h4>
    <p>2.0</p>
    <h4>Interceptions</h4>
    <p>1.0</p>
    <h4>Sacks</h4>
    <p>1.0</p>
    <h4>Fumbles Recovered</h4>
    <p>1.0</p>
    <h4>Defensive Shutout</h4>
    <p>5.0</p>
    <h4>Points for Less than 100 Rushing Yards Against</h4>
    <p>3.0</p>
    <h4>Points for Less than 150 Receiving Yards Against</h4>
    <p>4.0</p>
    <h3>Kickers</h3>
    <h4>FG Less than 40 Yd</h4>
    <p>3.0</p>
    <h4>FG 40-49 Yd</h4>
    <p>4.0</p>
    <h4>FG 50+ Yd</h4>
    <p>5.0</p>
    <h4>Extra Point Made</h4>
    <p>1.0</p>
  </>
);

const PaymentText = () => (
  <>
    <h1 level={2}>League Fee Payment</h1>
    <p>Entry fees are $55. Please send it to the Venmo link below.</p>
    <p>
      Also, please include your team name in the comment section so you can be
      checked off as paid.
    </p>
    <h3>
      <a href="https://venmo.com/code?user_id=1837080187502592468">
        Venmo Link
      </a>
    </h3>
  </>
);

const PopupModal = ({ modalVisible, setModalVisible }) => {
  return (
    <Modal
      title="The Rules"
      visible={modalVisible}
      onCancel={() => setModalVisible(null)}
      footer={
        <Button type="primary" onClick={() => setModalVisible(null)}>
          Got it!
        </Button>
      }
      className="custom-modal"
    >
      {modalVisible}
    </Modal>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setToken } = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);

  const handleGoogleLogin = async (response) => {
    if (response && response.credential) {
      // Save the ID token in Auth Context and Local Storage
      setToken(response.credential, response.expiresIn);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/google-signin`,
        response.credential,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );

      // Navigate to the next page after successful login
      navigate("/home-page");
    } else {
      message.error("Login failed");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundImage: "url('/football_field_background.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "32px",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card className="main-card">
          <h1 className="main-title">Man Cave Fantasy Football Playoffs</h1>
          <h2 className="main-card-text">Hosted by Mike McKellop</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "16px",
            }}
          >
            {isAuthenticated ? (
              <Button
                className="login-button"
                onClick={() => navigate("/home-page")}
              >
                View League
              </Button>
            ) : (
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => message.error("Login failed")}
              />
            )}
          </div>
        </Card>
      </div>

      <div className="emblem-container">
        <div
          className="emblem clickable-emblem"
          onClick={() => setModalVisible(RulesText)}
        >
          <h2 className="emblem-title">Full Rules</h2>
          <p className="emblem-text">Click here to view the full rules</p>
        </div>

        <div
          className="emblem clickable-emblem"
          onClick={() => setModalVisible(PaymentText)}
        >
          <h2 className="emblem-title">League Fee Payment</h2>
          <p className="emblem-text">Click here to view payment info</p>
        </div>

        <div className="emblem">
          <h2 className="emblem-title">Need help?</h2>
          <p className="emblem-text">
            Contact Mike at{" "}
            <a className="emblem-link" href="mailto:sierrafch2121@gmail.com">
              sierrafch2121@gmail.com
            </a>
          </p>
        </div>
      </div>

      <PopupModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </div>
  );
};

export default HomePage;
export { PopupModal as RulesModal };
