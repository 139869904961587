import statMap, { defStatMap, kickerStatMap } from "./statMap";
import { CheckCircleFilled } from "@ant-design/icons";

const getPayout = (rank) => {
  switch (rank) {
    case 1:
      return "700";
    case 2:
      return "525";
    case 3:
      return "350";
    case 4:
      return "175";
    case 5:
      return "";
    case 6:
      return "";
    default:
      return null;
  }
};

const posSortOrder = (pos) => {
  switch(pos) {
    case "QB": return 0;
    case "RB": return 1;
    case "WR": return 2;
    case "TE": return 3;
    case "K": return 4;
    case "DEF": return 5;
    default: return 6;
  }
}

const get_scoring_values = (scoringSettings) => {
  const values = { ...scoringSettings };
  values["rushYds"] = 1 / values["rushYds"];
  values["recYds"] = 1 / values["recYds"];
  values["passYds"] = 1 / values["passYds"];
  return values;
};

const addTotal = (data) => {
  const totals = {}
  for (let weeklyLineup of Object.values(data.lineups)){
    for (let player in weeklyLineup){
      if (totals[player] === undefined){
        totals[player] = JSON.parse(JSON.stringify(weeklyLineup[player]));
        totals[player].player.opponent = "--";
        totals[player].player.startTimestamp = -1;
        totals[player].player.gameId = -1;
      }
      else {
        totals[player].final_score += weeklyLineup[player].final_score;
        for (let stat in weeklyLineup[player].player.stats){
          totals[player].player.stats[stat] += weeklyLineup[player].player.stats[stat];
        }
      }
    }
  }

  const defTotals = {}
  for (let weeklyDefense of Object.values(data.defenses)){
    for (let defense in weeklyDefense){
      if (defTotals[defense] === undefined){
        defTotals[defense] = JSON.parse(JSON.stringify(weeklyDefense[defense]));
        defTotals[defense].team.opponent = "--";
        defTotals[defense].team.startTimestamp = -1;
        defTotals[defense].team.gameId = -1;
      }
      else {
        defTotals[defense].final_score += weeklyDefense[defense].final_score
        for (let stat in weeklyDefense[defense].team.stats){
          defTotals[defense].team.stats[stat] += weeklyDefense[defense].team.stats[stat]
        }
      }
    }
  }

  data.lineups["Total"] = totals
  data.defenses["Total"] = defTotals
  return data
}

const parseTeamData = (data) => {
  const res = [];
  for (let teamId in data) {
    res.push({ id: teamId, ...addTotal(data[teamId]) });
  }
  return res;
};

const parseDefLineupData = (data, rosterSettings) => {
  const res = [];
  for (let key in data) {
    res.push({
      position: "DEF",
      score: data[key].final_score,
      ...data[key].team,
      ...data[key].team.stats,
      ...data[key].team.count,
      scores: data[key].scores
    });
  }
  for (let i = 0; i < rosterSettings.df - res.length; i++) {
    res.push({ position: "DEF" });
  }

  return res;
};

const parseKickerLineupData = (data, rosterSettings) => {
  const roster = {
    k: [],
  };

  for (let key in data) {
    if (roster[data[key].player.position.toLowerCase()]) {
      roster[data[key].player.position.toLowerCase()].push({
        score: data[key].final_score,
        ...data[key].player,
        ...data[key].player.stats,
        ...data[key].player.count,
      });
    }
  }

  const res = [];
  for (let key in roster) {
    for (let player of roster[key]) {
      res.push(player);
    }
    for (let i = 0; i < rosterSettings[key] - roster[key].length; i++) {
      res.push({ position: key.toUpperCase() });
    }
  }

  return res;
};

const addWeeklyScores = (data) => {
  const scores = {}
  for (let week in data.lineups) {
    for (let player in data.lineups[week]) {
      if (scores[player] !== undefined){
        scores[player][week] = data.lineups[week][player].final_score;
      }
      else {
        scores[player] = {};
        scores[player][week] = data.lineups[week][player].final_score;
      }
    }
  }

  for (let week in data.lineups) {
    for (let player in data.lineups[week]) {
      data.lineups[week][player].scores = scores[player];
    }
  }

  const defScores = {}
  for (let week in data.defenses) {
    for (let defense in data.defenses[week]) {
      if (defScores[defense] !== undefined){
        defScores[defense][week] = data.defenses[week][defense].final_score;
      }
      else {
        defScores[defense] = {};
        defScores[defense][week] = data.defenses[week][defense].final_score;
      }
    }
  }

  for (let week in data.defenses) {
    for (let defense in data.defenses[week]) {
      data.defenses[week][defense].scores = defScores[defense];
    }
  }

  return data;
}

const parseLineupData = (data, rosterSettings) => {
  const roster = {
    qb: [],
    rb: [],
    wr: [],
    te: [],
  };

  for (let key in data) {
    if (roster[data[key].player.position.toLowerCase()]) {
      roster[data[key].player.position.toLowerCase()].push({
        score: data[key].final_score,
        ...data[key].player,
        ...data[key].player.stats,
        ...data[key].player.count,
        scores: data[key].scores
      });
    }
  }

  const res = [];
  for (let key in roster) {
    for (let player of roster[key]) {
      res.push(player);
    }
    for (let i = 0; i < rosterSettings[key] - roster[key].length; i++) {
      res.push({ position: key.toUpperCase() });
    }
  }

  return res;
};

const parsePlayers = (data, selectedTeamArr) => {
  const res = {};
  for (let player of data) {
    const pos = player.player.position;
    const obj = {
      score: player.score,
      opponent: player.opponent,
      startTimestamp: player.startTimestamp,
      ...player.player,
      ...player.stats,
    };
    try {
      res[pos].push(obj);
    } catch {
      res[pos] = [obj];
    }
  }

  for (let pos in res) {
    res[pos].sort(sortSelectedTeams(selectedTeamArr, "teamId"));
  }
  return res;
};

const parseDefenses = (data, selectedTeamArr) => {
  const res = [];
  for (let player of data) {
    const obj = {
      score: player.score,
      opponent: player.opponent,
      startTimestamp: player.startTimestamp,
      ...player.team,
      ...player.stats,
    };
    res.push(obj);
  }
  return res.sort(sortSelectedTeams(selectedTeamArr, "id"));
};

function convertUtcToUserTimezone(utcTimestamp) {
  // If your timestamp is in seconds
  const date = new Date(utcTimestamp * 1000);

  // Create a formatter (use options to format as desired)
  const options = {
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);

  // Format the date to a string
  return formatter.format(date);
}

const getStatCols = (withSorting) => {
  return Object.keys(statMap)
    .filter((key) => statMap[key])
    .map((key) => ({
      title: statMap[key],
      dataIndex: key,
      key: key,
      align: "right",
      ...(withSorting
        ? {
            sorter: (a, b) => a[key] - b[key],
            sortDirections: ["descending", "ascending"],
          }
        : {}),
    }));
};

const getKickerStatCols = (withSorting) => {
  return Object.keys(kickerStatMap)
    .filter((key) => kickerStatMap[key])
    .map((key) => ({
      title: kickerStatMap[key],
      dataIndex: key,
      key: key,
      align: "right",
      ...(withSorting
        ? {
            sorter: (a, b) => a[key] - b[key],
            sortDirections: ["descending", "ascending"],
          }
        : {}),
    }));
};

const getDefStatCols = (rush_thresh, rec_thresh, withSorting) => {
  const cols = Object.keys(defStatMap)
    .filter((key) => defStatMap[key])
    .map((key) => ({
      title: defStatMap[key],
      dataIndex: key,
      key: key,
      align: "right",
      ...(withSorting
        ? {
            sorter: (a, b) => a[key] - b[key],
            sortDirections: ["descending", "ascending"],
          }
        : {}),
    }));

  const rushYdsAgainstCol = {
    title: "Rush Yd Against",
    key: "rush_yds_against",
    dataIndex: "rush_yds_against",
    align: "right",
    ...(withSorting
      ? {
          sorter: (a, b) => a["rush_yds_against"] - b["rush_yds_against"],
          sortDirections: ["descending", "ascending"],
        }
      : {}),
    render: (val) => (
      <div
        style={
          0 < val < rush_thresh ? { color: "green", fontWeight: "bold" } : {}
        }
      >
        {val}
      </div>
    ),
  };

  const recYdsAgainstCol = {
    title: "Rec Yd Against",
    key: "rec_yds_against",
    dataIndex: "rec_yds_against",
    align: "right",
    ...(withSorting
      ? {
          sorter: (a, b) => a["rec_yds_against"] - b["rec_yds_against"],
          sortDirections: ["descending", "ascending"],
        }
      : {}),
    render: (val) => (
      <div
        style={
          0 < val < rec_thresh ? { color: "green", fontWeight: "bold" } : {}
        }
      >
        {val}
      </div>
    ),
  };

  const ptsAgainstCol = {
    title: "Shutout",
    key: "shutout",
    align: "right",
    ...(withSorting
      ? {
          sorter: (a, b) => a.shutout - b.shutout,
          sortDirections: ["descending", "ascending"],
        }
      : {}),
    render: (_, record) =>
      record.shutout === 1 ? (
        <CheckCircleFilled style={{ color: "green", fontSize: "1.5rem" }} />
      ) : null,
  };

  return [...cols, rushYdsAgainstCol, recYdsAgainstCol, ptsAgainstCol];
};

const formatDecimal = (value) => {
  try {
    return value.toFixed(0);
  } catch {
    return value;
  }
};

const sortSelectedTeams = (selectedTeams, idKey) => (a, b) => {
  const aInSelectedTeams = selectedTeams.includes(a[idKey]);
  const bInSelectedTeams = selectedTeams.includes(b[idKey]);

  if (aInSelectedTeams && !bInSelectedTeams) {
    return 1; // `a` goes after `b`
  }
  if (!aInSelectedTeams && bInSelectedTeams) {
    return -1; // `b` goes after `a`
  }
  return 0;
};

const sorterFunction = (selectedTeams, idKey, sortKey) => (a, b, sortOrder) => {
  const aInSelectedTeams = selectedTeams.includes(a[idKey]);
  const bInSelectedTeams = selectedTeams.includes(b[idKey]);

  if (aInSelectedTeams && !bInSelectedTeams) {
    return sortOrder === "ascend" ? 1 : -1; // `a` goes after `b`
  }
  if (!aInSelectedTeams && bInSelectedTeams) {
    return sortOrder === "descend" ? -1 : 1; // `b` goes after `a`
  }

  return a[sortKey] > b[sortKey] ? 1 : -1;
};

export {
  getPayout,
  get_scoring_values,
  parseTeamData,
  parseDefLineupData,
  parseKickerLineupData,
  parseLineupData,
  parsePlayers,
  parseDefenses,
  getStatCols,
  getKickerStatCols,
  getDefStatCols,
  formatDecimal,
  sorterFunction,
  convertUtcToUserTimezone,
  addWeeklyScores,
  posSortOrder
};
