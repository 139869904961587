import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  PageHeader,
  Select,
  Spin,
  Table,
  message,
  Typography,
  Tag,
  Form,
  Checkbox,
  InputNumber,
  Space,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axiosInstance, {
  axiosInstancePlain,
  setAuthToken,
} from "../utils/axiosConfig";
import { LeagueFormItems } from "./CreateLeaguePage";
import { RulesModal } from "./HomePage";
import {
  getPayout,
  get_scoring_values,
  parseTeamData,
  parseDefLineupData,
  parseKickerLineupData,
  parseLineupData,
  parsePlayers,
  parseDefenses,
  getStatCols,
  getKickerStatCols,
  getDefStatCols,
  formatDecimal,
  sorterFunction,
  convertUtcToUserTimezone,
  addWeeklyScores,
} from "../utils/LeaguePageUtils.js";
import FormItemLabel from "antd/es/form/FormItemLabel.js";
const { Search } = Input;
const { Title } = Typography;

const ViewLeaguePage = () => {
  const [loading, setLoading] = useState(true);
  const [loadingAvail, setLoadingAvail] = useState(true);
  const [loadingLeague, setLoadingLeague] = useState(true);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingUpdateSettings, setLoadingUpdateSettings] = useState(false);

  const [leagueData, setLeagueData] = useState({});
  const [teamData, setTeamData] = useState([]);
  const [myTeamData, setMyTeamData] = useState({});
  const [curWeek, setCurWeek] = useState(null);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [availableDefenses, setAvailableDefenses] = useState([]);

  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredDefenses, setFilteredDefenses] = useState([]);
  const [filteredTeamData, setFilteredTeamData] = useState([]);

  const [playerSearchValue, setPlayerSearchValue] = useState("");
  const [defenseSearchValue, setDefenseSearchValue] = useState("");
  const [teamSearchValue, setTeamSearchValue] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rulesModalVisible, setRulesModalVisible] = useState(false);

  const [selectedView, setSelectedView] = useState("myTeam");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState("Total");

  const [playerToDrop, setPlayerToDrop] = useState(null);
  const [defenseToDrop, setDefenseToDrop] = useState(null);
  const [positionToAdd, setPositionToAdd] = useState(null);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isDefAddModalVisible, setIsDefAddModalVisible] = useState(false);
  const [myTeamCols, setMyTeamCols] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);

  const [selectedTeams, setSelectedTeams] = useState([]);

  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const { teamId } = useParams();

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchData();
      fetchLeagueData();
    }
  }, [token]);

  useEffect(() => {
    if (playerSearchValue) {
      const escapedSearchValue = playerSearchValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(escapedSearchValue, "i"); // 'i' for case-insensitive
      const filteredPlayersTemp = {};
      for (let posKey in availablePlayers) {
        const playerData = availablePlayers[posKey];
        filteredPlayersTemp[posKey] = playerData.filter((player) =>
          regex.test(player.name)
        );
      }
      setFilteredPlayers(filteredPlayersTemp);
    } else {
      setFilteredPlayers(availablePlayers);
    }
  }, [playerSearchValue]);

  useEffect(() => {
    setFilteredPlayers(availablePlayers);
  }, [availablePlayers]);

  useEffect(() => {
    if (defenseSearchValue) {
      const escapedSearchValue = defenseSearchValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(escapedSearchValue, "i"); // 'i' for case-insensitive
      setFilteredDefenses(
        availableDefenses.filter((player) => regex.test(player.name))
      );
    } else {
      setFilteredDefenses(availableDefenses);
    }
  }, [defenseSearchValue]);

  useEffect(() => {
    setFilteredDefenses(availableDefenses);
  }, [availableDefenses]);

  useEffect(() => {
    if (teamSearchValue) {
      const escapedSearchValue = teamSearchValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(escapedSearchValue, "i"); // 'i' for case-insensitive
      setFilteredTeamData(teamData.filter((team) => regex.test(team.teamName)));
    } else {
      setFilteredTeamData(teamData);
    }
  }, [teamSearchValue]);

  useEffect(() => {
    setFilteredTeamData(teamData);
  }, [teamData]);

  useEffect(() => {
    const cols = [...playerCols, ...weeklyScoreCols, ...gameCols];

    if (leagueData && !leagueData.rostersLocked) {
      cols.unshift({
        title: "Add/Drop",
        key: "action",
        render: (_, record) => (
          <Button
            style={{ background: record.id ? "red" : "#02A43B" }}
            icon={
              record.id ? (
                <MinusOutlined style={{ color: "white" }} />
              ) : (
                <PlusOutlined style={{ color: "white" }} />
              )
            }
            onClick={() => {
              if (!!record.id) {
                setPlayerToDrop(record.id);
              }
              setPositionToAdd(record.position);
              setIsAddModalVisible(true);
            }}
          />
        ),
        width: "60px",
      });
    }

    setMyTeamCols(cols);
  }, [leagueData]);

  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder(sorter.order); // Update the sort order
    setSortedColumn(sorter.columnKey);
  };

  const fetchData = async () => {
    setLoading(true);
    setLoadingAvail(true);

    try {
      // Fetch teams and standings
      const [teamResponse, weekNoResponse] = await Promise.all([
        axiosInstance.get(`get-total-team-points/${teamId}`),
        axiosInstance.get(`/weekNo`),
      ]);

      setMyTeamData(addWeeklyScores(teamResponse.data));
      setLeagueData(teamResponse.data.league);
      setCurWeek(weekNoResponse.data);

      const selectedTeamArr = [];
      for (let playerId in teamResponse.data.lineups[weekNoResponse.data]) {
        let player =
          teamResponse.data.lineups[weekNoResponse.data][playerId].player;
        selectedTeamArr.push(player.teamId);
      }
      const defenseId = Object.keys(
        teamResponse.data.defenses[weekNoResponse.data]
      )[0];
      selectedTeamArr.push(Number(defenseId));
      setSelectedTeams(selectedTeamArr);

      setLoading(false);

      const availablePlayersResponse = await axiosInstance.get(
        `available-players/${teamResponse.data.id}`
      );
      setAvailablePlayers(
        parsePlayers(availablePlayersResponse.data.players, selectedTeamArr)
      );
      setAvailableDefenses(
        parseDefenses(availablePlayersResponse.data.defenses, selectedTeamArr)
      );
      setLoadingAvail(false);
    } catch (error) {
      // navigate("/join-league");
      // message.error(`Failed to load team: ${error.response.data.detail}`);
    }
  };

  const fetchLeagueData = async () => {
    setLoadingLeague(true);
    try {
      const leagueResponse = await axiosInstance.get(
        `get-league-total-info/${process.env.REACT_APP_LEAGUE_ID}`
      );
      setTeamData(parseTeamData(leagueResponse.data.teams));
    } catch (error) {
      // message.error(`Failed to load team: ${error.response.data.detail}`);
    }

    setLoadingLeague(false);
  };

  const showTeamModal = (team) => {
    setSelectedTeam(team);
    setIsModalVisible(true);
  };

  const handleAddPlayer = async (playerId) => {
    // API call logic to add the player
    try {
      setLoadingAdd(true);
      if (playerToDrop) {
        if (positionToAdd === "K") {
          await axiosInstancePlain.put(
            `teams/${myTeamData.id}/drop-kicker`,
            playerToDrop
          );
        } else {
          await axiosInstancePlain.put(
            `teams/${myTeamData.id}/drop-player`,
            playerToDrop
          );
          message.success("Player dropped successfully");
        }
      } else {
        // make add call
        if (positionToAdd === "K") {
          await axiosInstancePlain.put(
            `teams/${myTeamData.id}/add-kicker`,
            playerId.toString()
          );
        } else {
          await axiosInstancePlain.put(
            `teams/${myTeamData.id}/add-player`,
            playerId.toString()
          );
        }
        message.success("Player added successfully");
      }
    } catch (error) {
      message.error(`Failed to add player: ${error.response.data.detail}`);
    }
    setLoadingAdd(false);
    setIsAddModalVisible(false);
    setPlayerToDrop(null);
    setPositionToAdd(null);
    setPlayerSearchValue("");
    fetchData();
  };

  const handleAddDefense = async (teamId) => {
    // API call logic to add the player
    try {
      setLoadingAdd(true);
      if (defenseToDrop) {
        await axiosInstancePlain.put(
          `teams/${myTeamData.id}/drop-defense`,
          defenseToDrop
        );
        message.success("Defense dropped successfully");
      } else {
        // make add call
        await axiosInstancePlain.put(
          `teams/${myTeamData.id}/add-defense`,
          teamId.toString()
        );
        message.success("Defense added successfully");
      }
    } catch (error) {
      message.error(`Failed to add defense: ${error.response.data.detail}`);
    }
    setLoadingAdd(false);
    setIsDefAddModalVisible(false);
    setDefenseToDrop(null);
    setDefenseSearchValue("");
    fetchData();
  };

  const handleUpdateLeagueSettings = async (values) => {
    setLoadingUpdateSettings(true);

    // Here you would typically make an API request to create the league
    const payload = {
      passYds: 1 / values.passYds,
      passTds: values.passTds,
      ints: values.ints,
      passTwoPtConv: values.passTwoPtConv,
      rushYds: 1 / values.rushYds,
      rushTds: values.rushTds,
      rushTwoPtConv: values.rushTwoPtConv,
      recYds: 1 / values.recYds,
      recs: values.recs,
      recTds: values.recTds,
      recTwoPtConv: values.recTwoPtConv,
      otherTds: values.otherTds,
      fumbles: values.fumbles,
      // defensive
      def_td: values.def_td,
      xpRetTwoPtConv: values.xpRetTwoPtConv,
      ints_def: values.ints_def,
      sacks: values.sacks,
      fum_rec: values.fum_rec,
      shutout: values.shutout,
      pts_against_1: values.pts_against_1,
      pts_against_7: values.pts_against_7,
      pts_against_14: values.pts_against_14,
      pts_against_21: values.pts_against_21,
      pts_against_28: values.pts_against_28,
      pts_against_35: values.pts_against_35,
    };

    try {
      await axiosInstance.put(
        `/leagues/${leagueData.id}/update-scoring-settings`,
        payload
      );
      message.success("Scoring settings updated successfully!");
      fetchData();
      fetchLeagueData();
    } catch (error) {
      message.error(
        `Failed to update scoring settings: ${error.response.data.detail}`
      );
    }
    setLoadingUpdateSettings(false);
  };

  const handleUpdateTeamData = async (values) => {
    setLoadingUpdateSettings(true);

    if (values.teamName !== myTeamData.teamName) {
      try {
        await axiosInstance.put(
          `/teams/${myTeamData.id}/update-name`,
          values.teamName
        );
        message.success("Team name updated successfully!");
      } catch (error) {
        message.error(
          `Failed to update team name: ${error.response.data.detail}`
        );
      }
    }

    if (values.superBowlTotal !== myTeamData.superBowlTotal) {
      try {
        await axiosInstance.put(
          `/teams/${myTeamData.id}/update-super-bowl-total`,
          values.superBowlTotal
        );
        message.success("Super bowl total prediction updated successfully!");
        fetchData();
        fetchLeagueData();
      } catch (error) {
        message.error(
          `Failed to update team name: ${error.response.data.detail}`
        );
      }
    }

    fetchData();
    fetchLeagueData();
    setLoadingUpdateSettings(false);
  };

  const handleUpdateSettings = async (values) => {
    setLoadingUpdateSettings(true);

    if (values.leagueName !== leagueData.name) {
      try {
        await axiosInstance.put(
          `/leagues/${leagueData.id}/update-name`,
          values.leagueName
        );
        message.success("League name updated successfully!");
      } catch (error) {
        message.error(
          `Failed to update league name: ${error.response.data.detail}`
        );
      }
    }

    if (values.lockLeague !== leagueData.locked) {
      try {
        await axiosInstance.put(
          `/leagues/${leagueData.id}/lock-league`,
          values.lockLeague
        );
        message.success(
          `League lock${values.lockLeague ? "ed" : " removed"} successfully!`
        );
      } catch (error) {
        message.error(`Failed to lock league: ${error.response.data.detail}`);
      }
    }

    if (values.lockRosters !== leagueData.rostersLocked) {
      try {
        await axiosInstance.put(
          `/leagues/${leagueData.id}/lock-rosters`,
          values.lockRosters
        );
        message.success(
          `Rosters lock${values.lockRosters ? "ed" : " removed"} successfully!`
        );
      } catch (error) {
        message.error(`Failed to lock rosters: ${error.response.data.detail}`);
      }
    }
    fetchData();
    fetchLeagueData();
    setLoadingUpdateSettings(false);
  };

  const handlePaidStatusChange = async (teamId, paidStatus) => {
    try {
      setTeamData((prev) =>
        prev.map((team) => {
          if (team.id === teamId) {
            team.paid = paidStatus;
          }
          return team;
        })
      );

      const response = await axiosInstance.put(
        `/teams/${teamId}/update-paid`,
        paidStatus
      );
      // Handle successful response
      message.success(response.message);
    } catch (error) {
      message.error(`Error updating paid status: ${error}`);
      setTeamData((prev) =>
        prev.map((team) => {
          if (team.id === teamId) {
            team.paid = !paidStatus;
          }
          return team;
        })
      );
    }
  };

  const onPaidStatusChange = (checked, teamId) => {
    handlePaidStatusChange(teamId, checked);
  };

  const leagueCols = useMemo(() => {
    const columns = [
      {
        title: "Team Name",
        dataIndex: "teamName",
        key: "teamName",
      },
      {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: "Payout",
        key: "payout",
        render: (_, record) => getPayout(record.rank),
      },
      {
        title: "Total Points",
        dataIndex: "score",
        key: "score",
        render: (score) => formatDecimal(score),
      },
      {
        title: "Week 1",
        dataIndex: "scores",
        key: "week1",
        render: (score) => (score ? score[1] ?? 0 : 0),
      },
      {
        title: "Week 2",
        dataIndex: "scores",
        key: "week2",
        render: (score) => (score ? score[2] ?? 0 : 0),
      },
      {
        title: "Week 3",
        dataIndex: "scores",
        key: "week3",
        render: (score) => (score ? score[3] ?? 0 : 0),
      },
      {
        title: "Super Bowl",
        dataIndex: "scores",
        key: "week4",
        render: (score) => (score ? score[4] ?? 0 : 0),
      },
      {
        title: "Super Bowl Total",
        dataIndex: "superBowlTotal",
        key: "superBowlTotal",
      },
      // Add other relevant columns
      {
        title: "Paid?",
        key: "paid",
        disabled:
          myTeamData && leagueData
            ? myTeamData.userId !== leagueData.commissionerId
            : true,
        render: (_, record) => (
          <Checkbox
            onChange={(e) => onPaidStatusChange(e.target.checked, record.id)}
            disabled={myTeamData.userId !== leagueData.commissionerId}
            checked={record.paid}
          />
        ),
      },
    ];

    if (myTeamData.userId === leagueData.commissionerId) {
      columns.push({
        title: "Email",
        dataIndex: "email",
        key: "email",
      });
    }

    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => showTeamModal(record)}>View Team</Button>
      ),
    });

    return columns;
  }, [teamData, leagueData]);

  const gameCols = [
    {
      title: "Opponent",
      key: "opponent",
      render: (_, record) =>
        record.opponent
          ? `${
              record.opponent === "BYE" || record.opponent === "--"
                ? ""
                : record.isHome
                ? "vs "
                : "@ "
            }${record.opponent}`
          : null,
    },
    {
      title: "Gametime",
      key: "startTime",
      render: (_, record) =>
        record.startTimestamp > 0
          ? convertUtcToUserTimezone(record.startTimestamp)
          : null,
    },
  ];

  const playerCols = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "40px",
    },
    {
      title: "Player",
      key: "player",
      render: (_, record) => (
        <div
          style={{
            display: record.image ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.image}
            alt="Player"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Teams Rostered",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Total Points",
      dataIndex: "score",
      key: "score",
      render: (score) => formatDecimal(score),
    },
    // Add other rel
  ];

  const defenseCols = [
    {
      title: "Position",
      key: "position-def",
      width: "40px",
      render: () => "DEF",
    },
    {
      title: "Team",
      key: "team",
      render: (_, record) => (
        <div
          style={{
            display: record.logo ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.logo}
            alt="Logo"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Teams Rostered",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      render: (score) => formatDecimal(score),
    },
    // Add other rel
  ];

  const weeklyScoreCols = [
    {
      title: "Week 1",
      dataIndex: "scores",
      key: "week1",
      render: (score) => (score ? score[1] ?? 0 : 0),
    },
    {
      title: "Week 2",
      dataIndex: "scores",
      key: "week2",
      render: (score) => (score ? score[2] ?? 0 : 0),
    },
    {
      title: "Week 3",
      dataIndex: "scores",
      key: "week3",
      render: (score) => (score ? score[3] ?? 0 : 0),
    },
    {
      title: "Super Bowl",
      dataIndex: "scores",
      key: "week4",
      render: (score) => (score ? score[4] ?? 0 : 0),
    },
  ];

  const myDefCols = useMemo(() => {
    const cols = [...defenseCols, ...weeklyScoreCols, ...gameCols];

    if (leagueData && !leagueData.rostersLocked) {
      cols.unshift({
        title: "Add/Drop",
        key: "action",
        render: (_, record) => (
          <Button
            style={{ background: record.id ? "red" : "#02A43B" }}
            icon={
              record.id ? (
                <MinusOutlined style={{ color: "white" }} />
              ) : (
                <PlusOutlined style={{ color: "white" }} />
              )
            }
            onClick={() => {
              if (!!record.id) {
                setDefenseToDrop(record.id);
              }
              setIsDefAddModalVisible(true);
            }}
          />
        ),
        width: "60px",
      });
    }
    return cols;
  }, [myTeamData]);

  const myTeamWithStatsCols = [...myTeamCols, ...getStatCols(false)];

  const myKickerWithStatsCols = [...myTeamCols, ...getKickerStatCols(false)];

  const myDefWithStatsCols = useMemo(
    () => [
      ...myDefCols,
      ...getDefStatCols(
        leagueData?.scoringSettings?.rush_yds_against_bonus_threshold,
        leagueData?.scoringSettings?.rec_yds_against_bonus_threshold,
        false
      ),
    ],
    [leagueData]
  );

  const otherTeamWithStatsCols = [
    ...playerCols,
    ...gameCols,
    ...getStatCols(false),
  ];
  const otherKickerWithStatsCols = [
    ...playerCols,
    ...gameCols,
    ...getKickerStatCols(false),
  ];
  const otherDefWithStatsCols = useMemo(
    () => [
      ...defenseCols,
      ...gameCols,
      ...getDefStatCols(
        leagueData?.scoringSettings?.rush_yds_against_bonus_threshold,
        leagueData?.scoringSettings?.rec_yds_against_bonus_threshold,
        false
      ),
    ],
    [leagueData]
  );

  const availablePlayersCols = [
    {
      title: "Add",
      dataIndex: "id",
      key: "add",
      render: (id, record) => (
        <Button
          style={{
            background: selectedTeams.includes(record.teamId)
              ? "grey"
              : "#02A43B",
          }}
          disabled={selectedTeams.includes(record.teamId)}
          icon={<PlusOutlined style={{ color: "white" }} />}
          onClick={() => handleAddPlayer(id)}
        />
      ),
      width: "60px",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "40px",
    },
    {
      title: "Player",
      key: "player",
      sorter: (a, b) =>
        sorterFunction(selectedTeams, "teamId", "name")(a, b, sortOrder),
      sortOrder: sortedColumn === "player" ? sortOrder : null,
      render: (_, record) => (
        <div
          style={{
            display: record.image ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.image}
            alt="Player"
            className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
      sortDirections: ["descend", "ascend"],
      render: (score) => formatDecimal(score),
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      sorter: (a, b) =>
        sorterFunction(selectedTeams, "teamId", "team")(a, b, sortOrder),
      sortOrder: sortedColumn === "team" ? sortOrder : null, // Apply sortOrder if the column matches
    },
    ...gameCols,
  ];

  const availableDefensesCols = [
    {
      title: "Add",
      dataIndex: "id",
      key: "add",
      render: (id) => (
        <Button
          style={{
            background: selectedTeams.includes(id) ? "grey" : "#02A43B",
          }}
          disabled={selectedTeams.includes(id)}
          icon={<PlusOutlined style={{ color: "white" }} />}
          onClick={() => handleAddDefense(id)}
        />
      ),
      width: "60px",
    },
    {
      title: "Position",
      key: "position",
      width: "40px",
      render: () => "DEF",
    },
    {
      title: "Team",
      key: "team",
      sorter: (a, b) =>
        sorterFunction(selectedTeams, "id", "name")(a, b, sortOrder),
      sortOrder: sortedColumn === "team" ? sortOrder : null,
      render: (_, record) => (
        <div
          style={{
            display: record.logo ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.logo}
            alt="Logo"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
      sortDirections: ["descend", "ascend"],
      render: (score) => formatDecimal(score),
    },
    ...gameCols,
  ];

  const availablePlayersWithStatsCols = [
    ...availablePlayersCols,
    ...getStatCols(true),
  ];

  const availableKickersWithStatsCols = [
    ...availablePlayersCols,
    ...getKickerStatCols(true),
  ];

  const availableDefensesWithStatsCols = [
    ...availableDefensesCols,
    ...getDefStatCols(true),
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <PageHeader
            title={`${leagueData.name}`}
            style={{ marginBottom: "20px" }}
          />
          <div className="view-league-buttons">
            <Button.Group>
              <Button
                type={selectedView === "myTeam" ? "primary" : "default"}
                onClick={() => setSelectedView("myTeam")}
                size="large"
              >
                My Team
              </Button>
              <Button
                type={
                  selectedView === "leagueStandings" ? "primary" : "default"
                }
                onClick={() => setSelectedView("leagueStandings")}
                size="large"
              >
                Standings
              </Button>
              <Button
                type={selectedView === "settings" ? "primary" : "default"}
                onClick={() => setSelectedView("settings")}
                size="large"
              >
                Settings
              </Button>
            </Button.Group>
          </div>
          {selectedView === "settings" && (
            <>
              <Form
                layout="vertical"
                onFinish={handleUpdateTeamData}
                style={{ maxWidth: "500px", paddingBottom: "16px" }}
                initialValues={{
                  teamName: myTeamData.teamName,
                  superBowlTotal: myTeamData.superBowlTotal,
                }}
              >
                <h2>Team Settings</h2>
                <Form.Item label="Team Name" name="teamName">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Super Bowl Total Prediction"
                  style={{ width: "100%" }}
                  name="superBowlTotal"
                >
                  <InputNumber disabled={leagueData.rostersLocked} min={0} />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingUpdateSettings}
                >
                  Update Team
                </Button>
              </Form>

              <Form
                disabled={myTeamData.userId !== leagueData.commissionerId}
                layout="vertical"
                onFinish={handleUpdateSettings}
                style={{ maxWidth: "500px" }}
                initialValues={{
                  leagueName: leagueData.name,
                  lockLeague: leagueData.locked,
                  lockRosters: leagueData.rostersLocked,
                }}
              >
                <h2>League Settings</h2>
                <Form.Item label="League Name" name="leagueName">
                  <Input />
                </Form.Item>

                <Form.Item name="lockLeague" valuePropName="checked">
                  <Checkbox>Lock League?</Checkbox>
                </Form.Item>
                <Form.Item name="lockRosters" valuePropName="checked">
                  <Checkbox>Lock Rosters?</Checkbox>
                </Form.Item>
                {myTeamData.userId === leagueData.commissionerId && (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingUpdateSettings}
                    >
                      Update League
                    </Button>
                  </Form.Item>
                )}
              </Form>

              <h2>Scoring Settings</h2>
              <Button
                onClick={() => setRulesModalVisible(true)}
                style={{ marginBottom: 20 }}
                type="primary"
              >
                View All Rules
              </Button>
              <Form
                disabled={myTeamData.userId !== leagueData.commissionerId}
                layout="vertical"
                onFinish={handleUpdateLeagueSettings}
                style={{ maxWidth: "500px" }}
                initialValues={{
                  ...get_scoring_values(leagueData.scoringSettings),
                }}
              >
                <h4>
                  Note that a point is only counted when a player reaches the
                  threshold for a point; for example, a player rushing for 59
                  yards will only receive 5 points, and not 5.9.
                </h4>
                <LeagueFormItems />
                {myTeamData.userId === leagueData.commissionerId && (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingUpdateSettings}
                    >
                      Update Scoring Settings
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </>
          )}
          {selectedView === "leagueStandings" &&
            (loadingLeague ? (
              <Spin size="large" />
            ) : (
              <>
                <Title level={2}>League Standings</Title>
                <Search
                  placeholder="Search for teams"
                  onChange={(e) => setTeamSearchValue(e.target.value)}
                  style={{ marginBottom: 20, maxWidth: 400 }}
                />

                <Table
                  rowClassName={(record) =>
                    record.userId === myTeamData.userId
                      ? "table-row-highlight"
                      : ""
                  }
                  dataSource={filteredTeamData}
                  columns={leagueCols}
                  rowKey="id"
                  className="overflow-table"
                  pagination={false}
                />
              </>
            ))}
          {selectedView === "myTeam" && myTeamData && (
            <>
              <Title level={2}>{myTeamData.teamName}</Title>
              <Tag
                color="blue"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  padding: "16px",
                  margin: "0 0 16px 0",
                }}
              >
                Score: {formatDecimal(myTeamData.score)}
              </Tag>

              <Title level={4}>Super Bowl Total Score</Title>
              <Tag
                color="black"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "8px",
                  margin: "0 0 16px 0",
                }}
              >
                Guess: {myTeamData.superBowlTotal}
              </Tag>

              <Title level={3}>Roster</Title>
              <Title level={4}>Select Week</Title>
              <Select
                defaultValue={"Total"}
                style={{ width: 120, marginBottom: 20 }}
                onChange={(value) => setSelectedWeek(value)}
              >
                {Object.keys(myTeamData.lineups).map((week) => (
                  <Select.Option key={week} value={week}>
                  {week === "4" ? "Super Bowl" : (week === "Total" ? "Total" : `Week ${week}`)}
                  </Select.Option>
                ))}
              </Select>
              <Table
                rowClassName={(record) =>
                  record.eliminated ? "eliminated-player-row" : ""
                }
                dataSource={parseLineupData(
                  myTeamData.lineups[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={myTeamWithStatsCols}
                rowKey="id"
                className="overflow-table"
                pagination={false}
              />

              {leagueData.rosterSettings.df > 0 ? (
                <>
                  <Title level={3}>Defense</Title>
                  <Table
                    rowClassName={(record) =>
                      record.eliminated ? "eliminated-player-row" : ""
                    }
                    dataSource={parseDefLineupData(
                      myTeamData.defenses[selectedWeek || curWeek],
                      leagueData.rosterSettings
                    )}
                    columns={myDefWithStatsCols}
                    rowKey="id"
                    className="overflow-table"
                    pagination={false}
                  />
                </>
              ) : null}
              {leagueData.rosterSettings.k > 0 ? (
                <>
                  <Title level={3}>Kicker</Title>
                  <Table
                    rowClassName={(record) =>
                      record.eliminated ? "eliminated-player-row" : ""
                    }
                    dataSource={parseKickerLineupData(
                      myTeamData.lineups[selectedWeek || curWeek],
                      leagueData.rosterSettings
                    )}
                    columns={myKickerWithStatsCols}
                    rowKey="id"
                    className="overflow-table"
                    pagination={false}
                  />
                </>
              ) : null}
            </>
          )}
          {selectedTeam !== null ? (
            <Modal
              title={selectedTeam.teamName}
              visible={isModalVisible}
              width={5000} // Adjust the width as needed
              onCancel={() => setIsModalVisible(false)}
              footer={
                <Button onClick={() => setIsModalVisible(false)}>Close</Button>
              }
            >
              <h4 level={4}>Select Week</h4>
              <Select
                defaultValue={"Total"}
                style={{ width: 120, marginBottom: 20 }}
                onChange={(value) => setSelectedWeek(value)}
              >
                {Object.keys(selectedTeam.lineups).map((week) => (
                  <Select.Option key={week} value={week}>
                  {week === "4" ? "Super Bowl" : (week === "Total" ? "Total" : `Week ${week}`)}
                  </Select.Option>
                ))}
              </Select>

              <Table
                rowClassName={(record) =>
                  record.eliminated ? "eliminated-player-row" : ""
                }
                dataSource={parseLineupData(
                  selectedTeam.lineups[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={otherTeamWithStatsCols}
                pagination={false}
                className="overflow-table"
              />
              <Table
                rowClassName={(record) =>
                  record.eliminated ? "eliminated-player-row" : ""
                }
                dataSource={parseDefLineupData(
                  selectedTeam.defenses[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={otherDefWithStatsCols}
                pagination={false}
                className="overflow-table"
              />
              <Table
                rowClassName={(record) =>
                  record.eliminated ? "eliminated-player-row" : ""
                }
                dataSource={parseKickerLineupData(
                  selectedTeam.lineups[selectedWeek || curWeek],
                  leagueData.rosterSettings
                )}
                columns={otherKickerWithStatsCols}
                pagination={false}
                className="overflow-table"
              />
            </Modal>
          ) : null}
          {positionToAdd !== null ? (
            <Modal
              title={playerToDrop ? "Drop this player?" : "Available Players"}
              visible={isAddModalVisible}
              width="80%" // Adjust as needed
              onCancel={() => {
                setPositionToAdd(null);
                setIsAddModalVisible(false);
                setPlayerSearchValue("");
              }}
              footer={
                !playerToDrop ? (
                  <Button
                    disabled={loadingAdd}
                    onClick={() => {
                      setPositionToAdd(null);
                      setIsAddModalVisible(false);
                      setPlayerSearchValue("");
                    }}
                  >
                    Cancel
                  </Button>
                ) : null
              }
            >
              {!playerToDrop ? (
                loadingAvail || loadingAdd ? (
                  <Spin size="large" />
                ) : (
                  <>
                    <Search
                      placeholder="Search for players"
                      onChange={(e) => setPlayerSearchValue(e.target.value)}
                      style={{ marginBottom: 20 }}
                    />
                    <Table
                      rowClassName={(record) =>
                        record.eliminated ? "eliminated-player-row" : ""
                      }
                      dataSource={filteredPlayers[positionToAdd]}
                      columns={
                        positionToAdd === "K"
                          ? availableKickersWithStatsCols
                          : availablePlayersWithStatsCols
                      }
                      rowKey="id"
                      className="overflow-table"
                      onChange={handleTableChange}
                    />
                  </>
                )
              ) : (
                <Space>
                  <Button
                    onClick={() => {
                      setPlayerToDrop(null);
                      setIsAddModalVisible(false);
                      setPlayerSearchValue("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleAddPlayer(playerToDrop);
                    }}
                  >
                    Confirm
                  </Button>
                </Space>
              )}
            </Modal>
          ) : null}
          <Modal
            title={defenseToDrop ? "Drop this defense? " : "Available Defenses"}
            visible={isDefAddModalVisible}
            width="80%" // Adjust as needed
            onCancel={() => {
              setDefenseToDrop(null);
              setIsDefAddModalVisible(false);
              setDefenseSearchValue("");
            }}
            footer={
              !defenseToDrop ? (
                <Button
                  disabled={loadingAdd}
                  onClick={() => {
                    setDefenseToDrop(null);
                    setIsDefAddModalVisible(false);
                    setDefenseSearchValue("");
                  }}
                >
                  Cancel
                </Button>
              ) : null
            }
          >
            {!defenseToDrop ? (
              loadingAvail || loadingAdd ? (
                <Spin size="large" />
              ) : (
                <>
                  <Search
                    placeholder="Search for players"
                    onChange={(e) => setDefenseSearchValue(e.target.value)}
                    style={{ marginBottom: 20 }}
                  />

                  <Table
                    rowClassName={(record) =>
                      record.eliminated ? "eliminated-player-row" : ""
                    }
                    dataSource={filteredDefenses}
                    columns={availableDefensesWithStatsCols}
                    rowKey="id"
                    className="overflow-table"
                    onChange={handleTableChange}
                  />
                </>
              )
            ) : (
              <Space>
                <Button
                  onClick={() => {
                    setDefenseToDrop(null);
                    setIsDefAddModalVisible(false);
                    setDefenseSearchValue("");
                  }}
                >
                  Cancel
                </Button>
                {
                  <Button onClick={() => handleAddDefense(defenseToDrop)}>
                    Confirm
                  </Button>
                }
              </Space>
            )}
          </Modal>

          <RulesModal
            modalVisible={rulesModalVisible}
            setModalVisible={setRulesModalVisible}
          />
        </>
      )}
    </div>
  );
};

export default ViewLeaguePage;
